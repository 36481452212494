import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import loadable from '@loadable/component'

import { useWindowSize } from '../helpers/useWindowSize'

const PortableText = loadable(() => import('./PortableText'))

const Column = ({ columnClasses, column }) => {
  const [contentVisible, setContentVisible] = useState(false)
  const windowSize = useWindowSize()

  const isMobile = windowSize.width <= 768

  let contentClass = ''
  if (isMobile) {
    contentClass = !contentVisible ? '20px' : 'auto'
  }

  return (
    <div className={`pt-8 ${columnClasses}`}>
      <h3 className="font-bold text-14">{column.title}</h3>
      <motion.div
        className="overflow-hidden"
        animate={{
          height: contentClass || 'auto',
        }}
      >
        <PortableText blocks={column.content} className="Column" isColumn />
      </motion.div>
      {isMobile && !contentVisible && (
        <button
          type="button"
          className="font-bold text-14 text-blue-500"
          onClick={() => setContentVisible(true)}
        >
          + show all
        </button>
      )}
    </div>
  )
}

export default Column

Column.propTypes = {
  columnClasses: PropTypes.string,
  column: PropTypes.object,
}
